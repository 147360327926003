import { AbilityLib, AbilityType } from "../../../interfaces/Ability.interface";
import { cosmicReflection, existentialSurge, stoicShield, voidMeditation } from "./fx";
import { ReactComponent as CosmicReflection } from '../../../collections/philosophers/abilities/philosophers_cosmic-reflection.svg';
import { ReactComponent as ExistentialSurge } from '../../../collections/philosophers/abilities/philosophers_existential-surge.svg';
import { ReactComponent as StoicShield } from '../../../collections/philosophers/abilities/philosophers_stoic-shield.svg';
import { ReactComponent as VoidMeditation } from '../../../collections/philosophers/abilities/philosophers_void-meditation.svg';

const abilities: AbilityLib[] = [
    {
        "name": "Stoic Shield",
        "power": 40,
        "energy": 4,
        "group": false,
        "type": AbilityType.HealDefense,
        "self": true,
        "description": "Absorb the chaos of the universe with stoic resilience, reflecting a portion of damage back to attackers.",
        "cssClass": "stoic-shield",
        "icon": <StoicShield />,
        "method": stoicShield
    },
    {
        "name": "Existential Surge",
        "power": 60,
        "energy": 5,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Unleash a chaotic wave of raw energy, fueled by existential dread and defiance, hitting all enemies.",
        "cssClass": "existential-surge",
        "icon": <ExistentialSurge />,
        "method": existentialSurge
    },
    {
        "name": "Cosmic Reflection",
        "power": 50,
        "energy": 4,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Project the philosopher's inner mind into the cosmos, attacking with abstract thoughts that confuse and damage a single target.",
        "cssClass": "cosmic-reflection",
        "icon": <CosmicReflection />,
        "method": cosmicReflection
    },
    {
        "name": "Void Meditation",
        "power": 70,
        "energy": 6,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Delve into the abyss, weakening enemy defenses while unleashing dark energy across multiple foes.",
        "cssClass": "void-meditation",
        "icon": <VoidMeditation />,
        "method": voidMeditation
    }
];

export default abilities;