// React
import { useContext, useEffect, useState } from 'react';
import { PlayContext, PlayState } from '../../pages/PlayContext';

// Interfaces
import { EquipmentType } from '../../interfaces/Equipment.interface';

// Components
import PlayerCardEquipment from './PlayerCardEquipment';

// Colyseus
import { TokenMetadata } from '../../generated/TokenMetadata';

// Styles
import './PlayerCard.scss'

// Utils
import { useSpring, animated } from 'react-spring';
import { getOS } from '../../Utils';
import classNames from 'classnames';

import { cardDefaultDefense, cardDefaultHealth } from './PlayerCard';

interface PlayerCardProps {
  card: TokenMetadata;
  click?: () => void;
  clickFlip?: boolean;
  stats?: boolean;
}

const PlayerCardDisplay: React.FC<PlayerCardProps> = ({ card, click, clickFlip, stats }) => {

  const { prepareToken, frame, wearable }: PlayState = useContext(PlayContext);

  const os = getOS();

  const [cardFlipped, setCardFlipped] = useState<boolean>(true);

  // Equipment
  const [equipFrameEffect, setEquipFrameEffect] = useState<boolean>();
  const [equipWearableEffect, setEquipWearableEffect] = useState<boolean>();

  // Stats
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ prevHealth, setPrevHealth ] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ prevDefense, setPrevDefense ] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ healthDiff, setHealthDiff ] = useState<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ defenseDiff, setDefenseDiff ] = useState<number | null>(null);
  const [health, setHealth] = useState<number | null>(card.stats?.health || cardDefaultHealth);
  const [defense, setDefense] = useState<number | null>(card.stats?.defense || cardDefaultDefense);
  
  const { value: healthSpring } = useSpring({
    from: { value: prevHealth },
    to: { value: health },
    config: { duration: 500 },
  });
  
  const { value: defenseSpring } = useSpring({
    from: { value: prevDefense },
    to: { value: defense },
    config: { duration: 500 },
  });

  // Detect Stats Changes
  useEffect(() => {
    setDefense(card.stats?.defense)
    setHealth(card.stats?.health)
    // setDefenseDiff((JSON.parse(frame?.metadata as string)?.defense_boost || 0) + (JSON.parse(wearable?.metadata as string)?.defense_boost || 0))
    // setHealthDiff((JSON.parse(frame?.metadata as string)?.health_boost || 0) + (JSON.parse(wearable?.metadata as string)?.health_boost || 0))
  }, [card.stats.defense, card.stats.health, prevHealth, prevDefense, stats, frame, wearable])

  // Detect Frame Changes
  useEffect(() => {
    if (card.frame.id) {
      setEquipFrameEffect(true)
      setTimeout(() => {
        setEquipFrameEffect(false)
      }, 500)
    }
  }, [card.frame, card.frame?.id])

  // Detect Wearable Changes
  useEffect(() => {
    if (card.wearable.id) {
      setEquipWearableEffect(true)
      setTimeout(() => {
        setEquipWearableEffect(false)
      }, 500)
    }
  }, [card.wearable, card.wearable?.id])

  /**
   * Player Card Image
   * @param image Image URL
   * @returns Image URL
   */
  const image = () => {

    if (!card?.image) {
      return '';
    }

    function convertIPFSUrl(ipfsUrl: string) {
      const ipfsPrefix = 'ipfs://';
      const gatewayUrl = 'https://ipfs.io/ipfs/';
      if (ipfsUrl.startsWith(ipfsPrefix)) {
        return ipfsUrl.replace(ipfsPrefix, gatewayUrl);
      } else {
        return ipfsUrl; // Return the original URL if it doesn't start with 'ipfs://'
      }
    }

    if (card.image.startsWith('ipfs://')) {
      return convertIPFSUrl(card.image)
    } else {
      return card.image.replace('_a.png', '.png')
    }

  }

  /**
   * Player Card Classes
   */
  const cardClasses = classNames(
    'animate__animated',
    'player-card',
    'gladiator-arena',
    'flip-card',
    { flipped: cardFlipped },
    { 'not-flipped': !cardFlipped },
    `os-${os}`,
    `${click ? 'pointer' : ''}`,
    { 'selected': prepareToken && prepareToken.token_id === card.token_id }
  );

  return (
    <div id={`card_${card.collection}_${card.token_id}`} className={cardClasses} onClick={() => {
        if (click) {
            click()
        }
        if (clickFlip) {
            setCardFlipped(!cardFlipped)
        }
     }}>
        <div className={`aspect-ratio aspect-ratio--5x7`}>
            <div className="aspect-ratio--object">
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <div className='front-card-container'>
                        <div className='card-contents animate__animated animate__zoomIn'>
                            <div className="nft" data-tilt data-tilt-glare data-tilt-max-glare="0.4" data-tilt-max="6">
                                <div className="art-container-bg" />
                                <img className="art-container" src={`${ image() }`} loading='lazy' alt='' />
                                <div className={`card-frame ${equipFrameEffect ? `equip-effect` : ``}`}>
                                    <PlayerCardEquipment card={card} mode={EquipmentType.FRAMES} side='front' />
                                </div>
                                <div className="card-text">
                                    <div className="name pt-serif b ttu">{card.name}</div>
                                    <div className="token show-for-sr">{card?.token_id}</div>
                                    {stats ? (
                                    <div className="stats pt-serif b">
                                        {(defenseDiff !== null && defenseDiff !== 0) && (
                                            <div className="stat-change-difference defense-calc">
                                                <p className="ma0 mv0 f2 animate__animated animate__bounceIn">{defenseDiff > 0 ? <span className="green">+{defenseDiff}</span> : <span className="red">{defenseDiff}</span>}</p>
                                            </div>
                                        )}
                                        {(healthDiff !== null && healthDiff !== 0) && (
                                            <div className="stat-change-difference health-calc">
                                                <p className="ma0 mv0 f2 animate__animated animate__bounceIn">{healthDiff > 0 ? <span className="green">+{healthDiff}</span> : <span className="red">{healthDiff}</span>}</p>
                                            </div>
                                        )}
                                        <div className={`health ${health && health <= 0 ? `empty` : ``}`}>
                                            <animated.span className={`health-value mv0 lh-copy`}>
                                                {healthSpring.to((x: number) => Math.floor(x))}
                                            </animated.span>
                                        </div>
                                        <div className={`defense ${defense && defense <= 0 ? `empty` : ``}`}>
                                            <animated.span className={`defense-value mv0 lh-copy`}>
                                                {defenseSpring.to((x: number) => Math.floor(x))}
                                            </animated.span>
                                        </div>
                                    </div>
                                    ) : null}
                                </div>
                                <div className={`wearable-image ${equipWearableEffect ? `equip-effect` : ``}`}>
                                  <PlayerCardEquipment card={card} mode={EquipmentType.WEARABLES} side='front' />
                                </div>
                            </div>
                        </div>                      
                        </div>
                    </div>
                    <div className="flip-card-back">
                        <div className="back-card-container pointer"> 
                            <div className='card-contents animate__animated animate__zoomIn'>
                            <div className="nft" data-tilt data-tilt-glare data-tilt-max-glare="0.4" data-tilt-max="6">
                                <div className={`card-frame ${equipFrameEffect ? `equip-effect` : ``}`}>
                                  <PlayerCardEquipment card={card} mode={EquipmentType.FRAMES} side='back' />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>                  
                </div>
            </div>
        </div>
    </div>  
  );
};

export default PlayerCardDisplay;
