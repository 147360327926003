// React
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../Context';

// Colyseus
import { Ability } from '../../generated/Ability'

// Styles
import './AbilityIcon.scss'

// Interfaces
import { AbilityLib } from '../../interfaces/Ability.interface';

interface Props {
    ability: Ability;
}

const AbilityIcon: React.FC<Props> = ({ ability }) => {

  const { abilities } = useContext(UserContext);
  const [abilityLib, setAbilityLib] = useState<AbilityLib | undefined>();

  useEffect(() => {
    if (abilities) {
      const abilityLib = abilities.find((a) => a.name === ability.name);
      setAbilityLib(abilityLib);
    }
  }, [ability, abilities]);

  return (
    <>
    {abilityLib ? (
      <span className='ability-icon' data-ability={abilityLib.name}>
        {abilityLib.icon}
      </span>
    ) : null}
    </>
  )
}

export default AbilityIcon