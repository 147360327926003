// Config
import { LocalCollectionProperties } from "../../config/collections.interface";
import { CollectionSlug, spacesStorage } from "../../config/collections";
import { EquipmentType } from "../../interfaces/Equipment.interface";
import { BackdropTypes } from "../../types/Backdrops.type";

// Collection Slug
const slug: CollectionSlug = CollectionSlug.SacredMoths;

// Object Path
export const objectPath_sacredmoths = `${spacesStorage}/${slug}`;
const frames = `${objectPath_sacredmoths}/shop/cardframe`;
// const wearables = `${objectPath_sacredmoths}/shop/wearable`;
const backdrops = `${objectPath_sacredmoths}/backdrops`;

const collection: LocalCollectionProperties = {
    slugLocal: slug,
    logo: `${objectPath_sacredmoths}/welcome/logo.png`,
    bgImage: `${objectPath_sacredmoths}/welcome/welcome.jpg`,
    cardImages: [
        `${objectPath_sacredmoths}/welcome/card-2.png`, 
        `${objectPath_sacredmoths}/welcome/card-1.png`, 
        `${objectPath_sacredmoths}/welcome/card-3.png`,
    ],
    gameplay: {
        cardFront: `${objectPath_sacredmoths}/card/front.png`,
        cardBack: `${objectPath_sacredmoths}/card/back.png`
    },
    backdrops: [
        {
            name: 'Sacred Moth Terrarium',
            slug: BackdropTypes.SacredMothTerrarium,
            background: `${backdrops}/sacred-moth-terrarium/bg.png`,
            midground: `${backdrops}/sacred-moth-terrarium/mg.png`,
            foreground: `${backdrops}/sacred-moth-terrarium/fg.png`,
        },
        {
            name: 'Sacred Palace Ship',
            slug: BackdropTypes.SacredPalaceShip,
            background: `${backdrops}/sacred-palace-ship/bg.jpg`,
            midground: `${backdrops}/sacred-palace-ship/mg.png`,
            foreground: `${backdrops}/sacred-palace-ship/fg.png`,
        },
        {
            name: 'Space Colosseum',
            slug: BackdropTypes.SpaceColosseum,
            background: `${backdrops}/space-colosseum/bg.jpg`,
            midground: `${backdrops}/space-colosseum/mg.png`,
            foreground: `${backdrops}/space-colosseum/fg.png`,
        },
        {
            name: 'Moth Monument',
            slug: BackdropTypes.MothMonument,
            background: `${backdrops}/moth-monument/bg.jpg`,
            midground: `${backdrops}/moth-monument/mg.png`,
            foreground: `${backdrops}/moth-monument/fg.png`,
        },
    ],
    equipment: [
        {
            id: 3,
            type: EquipmentType.FRAMES,
            image_front: `${frames}_front-diamond-moth.png`,
            image_back: `${frames}_back-diamond-moth.png`,
            thumbnail: `${frames}_sacred-moths_diamond-moth-frame.jpg`
        }
    ],
};

export default collection;