// Interfaces
import { UserState } from "../interfaces/User.interface";

// Config
import { CollectionSlug } from "./collections";

// Types
import { GameModeTypesInterface } from "../types/GameModes.type";

// Components
import H5AudioPlayer from "react-h5-audio-player";

// Colyseus
import * as Colyseus from "colyseus.js";
import { Web3Provider } from "../web3/web3";

// Demo Wallet
export const demoWallet = '0xE671ee188461b5dA36d872678c92E41c293A25A9';

// Default State
export const defaultUserState: UserState = {

    loggedIn: false,
    setLoggedIn: (boolean: boolean) => {},

    // History
    router: null,

    // User
    user: {
        chainID: null,
        wallet: null,
        collectibles: [],
        socket: null,
        ethBalance: 0,
        erc20Balance: 0,
        gladiiBalance: 0,
        player: null,
    },

    // Wallet
    chooseProvider: (provider: Web3Provider) => {},
    connectingWallet: false,
    setConnectingWallet: () => {},
    tokensLoaded: false,
    walletInfo: false,
    setWalletInfo: () => {},

    // Collections
    collections: [],
    setCollections: () => {},

    // Abilities
    abilities: [],
    setAbilities: () => {},

    // Collectibles
    displayCollectiblesLoaded: false,
    collectibleCount: 0,
    setCollectibleCount: () => {},
    collectiblesLoaded: 0,
    setCollectiblesLoaded: () => {},

    // Get Player Stats
    playerStats: () => {},

    // Theme
    chooseTheme: false,
    setChooseTheme: () => {},
    theme: null,
    changeTheme: (theme: CollectionSlug | null) => {},

    // Game mode
    mode: null,
    setGameMode: (mode: GameModeTypesInterface) => {},

    // Settings
    settings: false,
    setSettings: () => {},

    // Audio
    audioPlaying: false,
    setAudioPlaying: () => {},
    musicPlayer: null,
    setMusicPlayer: (player: H5AudioPlayer) => {},

    // Alert Modal
    alertModal: false,
    setAlertModal: () => {},
    alertMessage: '',
    setAlertMessage: () => {},
    alertHeader: '',
    setAlertHeader: () => {},

    // Socket for Game Server
    setSocket: (socket: Colyseus.Client) => {},

    // Demo Mode
    demo: false,
    playDemo: () => {},

    // 0x
    zeroXModal: false,
    setZeroXModal: () => {},

    // Shop Items
    balanceCheck: () => {},
    shopItems: [],
    setShopItems: () => {},
    getShopItems: () => {},

    // Purchase Modal
    waitModal: false,
    setWaitModal: () => {},

    // Tooltip
    tooltip: { type: null, data: null },
    setTooltip: () => {},
    showTooltip: false,
    setShowTooltip: () => {},

    // Testnet
    testnet: false,

    // Logout
    logout: () => {},
};