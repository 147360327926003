import { applyEffects } from "../../../fx/fx-utils";
import { TokenMetadata } from "../../../generated/TokenMetadata";
import { AbilityMode } from "../../../types/Abilities.type";

/**
 * Dominance Protocol: A powerful ability applied to the source card
 * @param source Card activating the ability
 * @returns Boolean
 */
export function dominanceProtocol(source: TokenMetadata) {
    return new Promise<boolean>((resolve) => {
        const sourceElement = document.getElementById(`card_${source.collection}_${source.token_id}`);

        if (sourceElement) {
            applyEffects('dominance-protocol', 'cardAbility', sourceElement).then(() => {
                resolve(true);
            });
        }
    });
}

/**
 * Chaotic Lash: An attack ability that affects a single target
 * @param source Card sending the attack
 * @param target Card receiving the attack
 * @param context Ability mode or other context
 * @returns Boolean
 */
export function chaoticLash(source: TokenMetadata, target: TokenMetadata, context: AbilityMode) {
    return new Promise<boolean>((resolve) => {
        const sourceElement = document.getElementById(`card_${source.collection}_${source.token_id}`);
        const targetElement = document.getElementById(`card_${target.collection}_${target.token_id}`);

        if (sourceElement && targetElement) {
            applyEffects('chaotic-lash', context, targetElement, sourceElement).then(() => {
                resolve(true);
            });
        }
    });
}

/**
 * Apex Strike: A decisive strike ability aimed at a single target
 * @param source Card sending the attack
 * @param target Card receiving the attack
 * @param context Ability mode or other context
 * @returns Boolean
 */
export function apexStrike(source: TokenMetadata, target: TokenMetadata, context: AbilityMode) {
    return new Promise<boolean>((resolve) => {
        const sourceElement = document.getElementById(`card_${source.collection}_${source.token_id}`);
        const targetElement = document.getElementById(`card_${target.collection}_${target.token_id}`);

        if (sourceElement && targetElement) {
            applyEffects('apex-strike', context, targetElement, sourceElement).then(() => {
                resolve(true);
            });
        }
    });
}

/**
 * Corruption Wave: A group attack affecting multiple targets
 * @param source Card sending the attack
 * @param target Cards receiving the attack
 * @param context Ability mode or other context
 * @returns Boolean
 */
export function corruptionWave(source: TokenMetadata, target: TokenMetadata[], context: AbilityMode) {
    return new Promise<boolean>((resolve) => {
        const sourceElement = document.getElementById(`card_${source.collection}_${source.token_id}`);

        if (sourceElement && target) {
            const targets = target
                .map(t => document.getElementById(`card_${t.collection}_${t.token_id}`))
                .filter(t => t !== null);

            applyEffects('corruption-wave', context, targets as HTMLElement[], sourceElement).then(() => {
                resolve(true);
            });
        }
    });
}
