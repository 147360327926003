import { AbilityLib, AbilityType } from "../../../interfaces/Ability.interface";
import { galacticDustBeam, hyperdriveFlare, nebulaPulse, stardustRevive } from "./fx";
import { ReactComponent as GalacticDustBeam } from '../../../collections/sacredmoths/abilities/sacredmoths_galactic-dust-beam.svg';
import { ReactComponent as HyperdriveFlare } from '../../../collections/sacredmoths/abilities/sacredmoths_hyperdrive-flare.svg';
import { ReactComponent as NebulaPulse } from '../../../collections/sacredmoths/abilities/sacredmoths_nebula-pulse.svg';
import { ReactComponent as StardustRevive } from '../../../collections/sacredmoths/abilities/sacredmoths_stardust-regeneration.svg';

const abilities: AbilityLib[] = [
    {
        "name": "Hyperdrive Flare",
        "power": 50,
        "energy": 5,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Ignite a blinding flare of cosmic light, damaging all enemies in its radiant blast.",
        "cssClass": "hyperdrive-flare",
        "icon": <HyperdriveFlare />,
        "method": hyperdriveFlare,
        "sound": {
            "title": "Energy Blasts",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/ES_Energy%20Blasts%20-%20SFX%20Producer.mp3",
            "soundsLike": "laser, energy, slashes"
        }
    },
    {
        "name": "Galactic Dust Beam",
        "power": 25,
        "energy": 3,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Fire a focused beam of galactic particles, piercing a single target with precision.",
        "cssClass": "galactic-dust-beam",
        "icon": <GalacticDustBeam />,
        "method": galacticDustBeam,
        "sound": {
            "title": "PREL Laser 2",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/ES_PREL%20Laser%202%20-%20SFX%20Producer.mp3",
            "soundsLike": "cosmic laser, hollow sounding"
        }
    },
    {
        "name": "Nebula Pulse",
        "power": 20,
        "energy": 1,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Release a short burst of nebula energy, dealing quick damage to a single foe.",
        "cssClass": "nebula-pulse",
        "icon": <NebulaPulse />,
        "method": nebulaPulse,
        "sound": {
            "title": "PREL Electrical 25",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/ES_PREL%20Electrical%2025%20-%20SFX%20Producer.mp3",
            "soundsLike": "electrical shock / pops"
        }
    },
    {
        "name": "Stardust Revive",
        "power": 25,
        "energy": 1,
        "group": false,
        "self": true,
        "type": AbilityType.HealDefense,
        "description": "Harness the power of stardust to heal and strengthen your defenses, ensuring survival in battle.",
        "cssClass": "stardust-revive",
        "icon": <StardustRevive />,
        "method": stardustRevive,
        "sound": {
            "title": "Tonal Impact",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/ES_Tonal%20Impact%20-%20SFX%20Producer.mp3",
            "soundsLike": "laser charge"
        }
    }
];

export default abilities;