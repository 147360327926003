import { AbilityNames } from "../types/Abilities.type";

export enum AbilityType {
    Attack = 'attack',
    HealDefense = 'heal-defense'
}

export interface AbilityLib {
    name: AbilityNames;
    power: number;
    energy: number;
    group: boolean;
    type: AbilityType;
    self?: boolean;
    description: string;
    cssClass: string;
    icon?: JSX.Element;
    method?: Function | null;
    sound?: AbilitySound;
}

export interface AbilitySound {
    title: string;
    url: string;
    soundsLike: string;
}

export interface Ability extends AbilityLib {
    id: number;
}
