// Config
import { LocalCollectionProperties } from "../../config/collections.interface";
import { CollectionSlug, spacesStorage } from "../../config/collections";
import { EquipmentType } from "../../interfaces/Equipment.interface";
import { BackdropTypes } from "../../types/Backdrops.type";

// Collection Slug
const slug: CollectionSlug = CollectionSlug.CosmicMuffins;

// Object Path
export const objectPath_cosmicmuffins = `${spacesStorage}/${slug}`;
const frames = `${objectPath_cosmicmuffins}/shop/cardframe`;
const wearables = `${objectPath_cosmicmuffins}/shop/wearable`;
const backdrops = `${objectPath_cosmicmuffins}/backdrops`;

const collection: LocalCollectionProperties = {
    slugLocal: slug,
    logo: `${objectPath_cosmicmuffins}/welcome/logo.png`,
    bgImage: `${objectPath_cosmicmuffins}/welcome/welcome.jpg`,
    cardImages: [
        `${objectPath_cosmicmuffins}/welcome/card-2.png`, 
        `${objectPath_cosmicmuffins}/welcome/card-1.png`, 
        `${objectPath_cosmicmuffins}/welcome/card-3.png`
    ],
    gameplay: {
        cardFront: `${objectPath_cosmicmuffins}/card/front.png`,
        cardBack: `${objectPath_cosmicmuffins}/card/back.png`
    },
    backdrops: [
        {
            name: 'Planet Macaron',
            slug: BackdropTypes.PlanetMacaron,
            background: `${backdrops}/${BackdropTypes.PlanetMacaron}/bg.png`,
            midground: `${backdrops}/${BackdropTypes.PlanetMacaron}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.PlanetMacaron}/fg.png`,
        },
        {
            name: 'Planet Macaron Snow',
            slug: BackdropTypes.PlanetMacaronSnow,
            background: `${backdrops}/${BackdropTypes.PlanetMacaronSnow}/bg.jpg`,
            midground: `${backdrops}/${BackdropTypes.PlanetMacaronSnow}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.PlanetMacaronSnow}/fg.png`,
        },
        {
            name: 'Planet Macaron Atmosphere',
            slug: BackdropTypes.MacaronAtmosphere,
            background: `${backdrops}/${BackdropTypes.MacaronAtmosphere}/bg.jpg`,
            midground: `${backdrops}/${BackdropTypes.MacaronAtmosphere}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.MacaronAtmosphere}/fg.png`,
        },
        {
            name: 'Planet Macaron Arctic',
            slug: BackdropTypes.MacaronArctic,
            background: `${backdrops}/${BackdropTypes.MacaronArctic}/bg.jpg`,
            midground: `${backdrops}/${BackdropTypes.MacaronArctic}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.MacaronArctic}/fg.png`,
        }
    ],
    equipment: [
        {
            id: 5,
            type: EquipmentType.FRAMES,
            image_front: `${frames}_front-golden-muffin.png`,
            image_back: `${frames}_back-golden-muffin.png`,
            thumbnail: `${frames}_cosmic-muffins_golden-muffin-frame.jpg`
        },
        {
            id: 4,
            type: EquipmentType.WEARABLES,
            image_front: `${wearables}_comet-crystals.png`,
            thumbnail: `${wearables}_cosmic-muffins_comet-crystals.png`
        },
        {
            id: 6,
            type: EquipmentType.WEARABLES,
            image_front: `${wearables}_supernova-stones.png`,
            thumbnail: `${wearables}_cosmic-muffins_supernova-stones.png`            
        },
        {
            id: 7,
            type: EquipmentType.WEARABLES,
            image_front: `${wearables}_astro-amulet.png`,
            thumbnail: `${wearables}_cosmic-muffins_astro-amulet.png`
        },
        {
            id: 8,
            type: EquipmentType.WEARABLES,
            image_front: `${wearables}_noggles.png`,
            thumbnail: `${wearables}_cosmic-muffins_noggles.png`
        }
    ]
};

export default collection;