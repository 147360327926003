import { applyEffects } from "../../../fx/fx-utils";
import { TokenMetadata } from "../../../generated/TokenMetadata";
import { AbilityMode } from "../../../types/Abilities.type";

/**
 * Stoic Shield: Defensive ability for a card
 * @param source Card activating the shield
 * @returns Boolean
 */
export function stoicShield(source: TokenMetadata) {
    return new Promise<boolean>((resolve) => {
        const sourceElement = document.getElementById(`card_${source.collection}_${source.token_id}`);

        if (sourceElement) {
            applyEffects('stoic-shield', 'cardAbility', sourceElement).then(() => {
                resolve(true);
            });
        }
    });
}

/**
 * Existential Surge: Group attack affecting multiple targets
 * @param source Card sending the attack
 * @param target Cards receiving the attack
 * @param context Ability mode or other context
 * @returns Boolean
 */
export function existentialSurge(source: TokenMetadata, target: TokenMetadata[], context: AbilityMode) {
    return new Promise<boolean>((resolve) => {
        const sourceElement = document.getElementById(`card_${source.collection}_${source.token_id}`);

        if (sourceElement && target) {
            const targets = target
                .map(t => document.getElementById(`card_${t.collection}_${t.token_id}`))
                .filter(t => t !== null);

            applyEffects('existential-surge', context, targets as HTMLElement[], sourceElement).then(() => {
                resolve(true);
            });
        }
    });
}

/**
 * Cosmic Reflection: Reflect attack back to the attacker
 * @param source Card reflecting the attack
 * @param target Card receiving the reflection
 * @param context Ability mode or other context
 * @returns Boolean
 */
export function cosmicReflection(source: TokenMetadata, target: TokenMetadata, context: AbilityMode) {
    return new Promise<boolean>((resolve) => {
        const sourceElement = document.getElementById(`card_${source.collection}_${source.token_id}`);
        const targetElement = document.getElementById(`card_${target.collection}_${target.token_id}`);

        if (sourceElement && targetElement) {
            applyEffects('cosmic-reflection', context, targetElement, sourceElement).then(() => {
                resolve(true);
            });
        }
    });
}

/**
 * Void Meditation: Restores health or defense value for a card
 * @param source Card meditating
 * @param target Target card (optional) for effects
 * @param context Ability mode or other context
 * @returns Boolean
 */
export function voidMeditation(source: TokenMetadata, target: TokenMetadata, context: AbilityMode) {
    return new Promise<boolean>((resolve) => {
        const sourceElement = document.getElementById(`card_${source.collection}_${source.token_id}`);
        const targetElement = target ? document.getElementById(`card_${target.collection}_${target.token_id}`) : null;

        if (sourceElement && targetElement) {
            applyEffects('void-meditation', context, sourceElement, targetElement).then(() => {
                resolve(true);
            });
        }
    });
}
