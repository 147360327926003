// React
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Context';

// Ionic
import { IonContent, IonPage, IonSpinner } from '@ionic/react';

// Interfaces
import { TopPlayers } from '../interfaces/Leaderboard.interface';

// Components
import LeaderboardPlacement from './LeaderboardPlacement';
import BottomNav from '../components/ui/BottomNav';

// Services
import { getTopPlayers } from '../supabase/api';

// Utils
import { getOrdinalNumber, shortenWalletAddress, tilt } from '../Utils';

const Leaderboard: React.FC = () => {

  const { router, collections } = useContext(UserContext);
  const [ topPlayers, setTopPlayers ] = useState<TopPlayers[]>([]);
  const [ loading, setLoading ] = useState<boolean>(true);

  useEffect(() => {
    if (router?.routeInfo.pathname.startsWith('/leaderboard') && collections.length) {
        getTopPlayers().then((res) => {
            setTopPlayers(res);
            setLoading(false);
            setTimeout(() => {
                tilt('tilt-js-leaderboard');
            }, 250)
        }).catch((err) => {
            console.log(err);
        });
    }
  }, [router?.routeInfo.pathname, collections])

  return (
    <IonPage className='gladiator-page leaderboard'>
        <IonContent fullscreen className='transparent'>
            <div className="gladiator-leaderboard-container">
                <div className="page-content">
                    <div className="relative row">
                        <div className="column small-12 tc ma0 pa0 text-drop-shadow">
                            <h2 className='gladiator-arena ml3'>Leaderboard</h2>
                        </div>
                    </div>
                    <div className="relative row scrollable-page-content">
                        <div className="gladiator-top-border" />
                        {/* <div className="gladiator-bottom-border" /> */}
                        <div className="column small-12 relative z-4 flex flex-wrap justify-center items-start">
                            <div className="row player-placement-row items-end w-100 mb4">
                                {loading ? (
                                    <div className="small-12 tc">
                                        <IonSpinner className='spinner' name="crescent" />
                                    </div>
                                ) : (
                                    <>
                                    <div className="small-12 large-4 tc first-place mb4">
                                        <LeaderboardPlacement placement={1} player={topPlayers[0]} />
                                    </div>
                                    <div className="small-12 large-4 tc second-place mb4">
                                        <LeaderboardPlacement placement={2} player={topPlayers[1]} />
                                    </div>
                                    <div className="small-12 large-4 tc third-place mb4">
                                        <LeaderboardPlacement placement={3} player={topPlayers[2]} />
                                    </div>
                                    </>
                                )}
                            </div>
                            {!loading ? (
                                <>
                                <div className="dn">
                                    <div className="row flex flex-wrap justify-center items-center hide-for-large">
                                        {topPlayers.map((p, i) => {
                                            if (i < 3) return null;
                                            return (
                                                <div className="small-12 large-4 tc other-place mb4">
                                                    <LeaderboardPlacement placement={i + 1} player={topPlayers[i]} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="row player-placement-table justify-center items-center ph4 mb4 table animate__animated animate__fadeInUp show-for-large">
                                    <div className="column small-12">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="column small-2">
                                                    <h3 className='gladiator-arena'>Rank</h3>
                                                </div>
                                                <div className="column small-2 tl dn">
                                                    <h3 className='gladiator-arena'>Token</h3>
                                                </div>
                                                <div className="column small-4 tc">
                                                    <h3 className='gladiator-arena'>Wallet</h3>
                                                </div>
                                                <div className="column small-2 tc">
                                                    <h3 className='gladiator-arena'>Wins</h3>
                                                </div>
                                                <div className="column small-2 tc">
                                                    <h3 className='gladiator-arena'>Losses</h3>
                                                </div>
                                                <div className="column small-2 tr">
                                                    <h3 className='gladiator-arena'>Ties</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-body">
                                            {topPlayers.map((p, i) => {
                                                if (i < 3) return null;
                                                return (
                                                    <div key={`player-${i}`} className="row bb b--white-10 mb3 flex items-center">
                                                        <div className="column small-2 tl">
                                                            <p className="gladiator-arena mv1 f4">{getOrdinalNumber(i + 1)}</p>
                                                        </div>
                                                        <div className="column small-2 tc dn">
                                                            <LeaderboardPlacement placement={i + 1} player={topPlayers[i]} stats={false} />
                                                        </div>
                                                        <div className="column small-4 tc">
                                                            <p className="gladiator-arena mv1 f4">{shortenWalletAddress(p.wallet_id)}</p>
                                                        </div>
                                                        <div className="column small-2 tc">
                                                            <p className="gladiator-arena mv1 f4">{p.total_wins.toLocaleString()}</p>
                                                        </div>
                                                        <div className="column small-2 tc">
                                                            <p className="gladiator-arena mv1 f4">{p.total_wins.toLocaleString()}</p>
                                                        </div>
                                                        <div className="column small-2 tr">
                                                            <p className="gladiator-arena mv1 f4">{p.ties.toLocaleString()}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div id="tilt-js-leaderboard" />
        </IonContent>
        <BottomNav />
    </IonPage>
  );
};

export default Leaderboard;
