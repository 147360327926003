import { AbilityLib, AbilityType } from "../../../interfaces/Ability.interface";
import { ReactComponent as BuffBash } from '../../../collections/bufficorns/abilities/bufficorns_buffbash.svg';
import { ReactComponent as SporkLift } from '../../../collections/bufficorns/abilities/bufficorns_sporklift.svg';
import { ReactComponent as MergeConflict } from '../../../collections/bufficorns/abilities/bufficorns_mergeconflict.svg';
import { ReactComponent as SporkWhaleShield } from '../../../collections/bufficorns/abilities/bufficorns_sporkwhale.svg';
import { buffBash, mergeConflict, sporkLift, sporkWhale } from "./fx";

const abilities: AbilityLib[] = [
    {
        "name": "Buff Bash",
        "power": 60,
        "energy": 5,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Channel the full force of a Bufficorn's mighty horns, bulldozing through bugs and blockers with developer fury.",
        "cssClass": "buff-bash",
        "method": buffBash,
        "icon": <BuffBash />
    },
    {
        "name": "Sporklift",
        "power": 50,
        "energy": 4,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Raise your spork to the sky, elevating your build and dropping a massive payload of code onto all enemies.",
        "cssClass": "sporklift",
        "method": sporkLift,
        "icon": <SporkLift />
    },
    {
        "name": "Merge Conflict",
        "power": 45,
        "energy": 3,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Dive into a chaotic clash of code, striking all foes with the destructive force of unresolved merge conflicts.",
        "cssClass": "merge-conflict",
        "method": mergeConflict,
        "icon": <MergeConflict />
    },
    {
        "name": "SporkWhale Shield",
        "power": 30,
        "energy": 2,
        "group": false,
        "type": AbilityType.HealDefense,
        "self": true,
        "description": "Summon the spirit of the mighty SporkWhale, restoring your shield fortifying against incoming threats.",
        "cssClass": "sporkwhale-shield",
        "method": sporkWhale,
        "icon": <SporkWhaleShield />
    }
];

export default abilities;