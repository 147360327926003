// Config
import { LocalCollectionProperties } from "../../config/collections.interface";
import { CollectionSlug, spacesStorage } from "../../config/collections";
import { BackdropTypes } from "../../types/Backdrops.type";

// Collection Slug
const slug: CollectionSlug = CollectionSlug.Philosophers;

// Object Path
export const objectPath_philosophers = `${spacesStorage}/${slug}`;
// const frames = `${objectPath_philosophers}/shop/cardframe`;
// const wearables = `${objectPath_philosophers}/shop/wearable`;
const backdrops = `${objectPath_philosophers}/backdrops`;

const collection: LocalCollectionProperties = {
    slugLocal: slug,
    logo: `${objectPath_philosophers}/welcome/logo.png`,
    bgImage: `${objectPath_philosophers}/welcome/welcome.jpg`,
    cardImages: [
        `${objectPath_philosophers}/welcome/card-2.png`, 
        `${objectPath_philosophers}/welcome/card-1.png`, 
        `${objectPath_philosophers}/welcome/card-3.png`,
    ],
    gameplay: {
        cardFront: `${objectPath_philosophers}/card/front.png`,
        cardBack: `${objectPath_philosophers}/card/back.png`
    },
    backdrops: [
        {
            name: 'Halls of Wisdom',
            slug: BackdropTypes.HallsOfWisdom,
            background: `${backdrops}/${BackdropTypes.HallsOfWisdom}/bg.jpg`,
            midground: `${backdrops}/${BackdropTypes.HallsOfWisdom}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.HallsOfWisdom}/fg.png`,
        },
    ],
    equipment: [
    ],
};

export default collection;