// Config
import { CollectionSlug } from '../config/collections';

// React
import { UserContext } from '../Context';
import { useContext, useEffect, useState } from 'react';
import { PlayContext, PlayState } from './PlayContext';

// Ionic
import { IonBadge, IonButton, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonList, IonPage, IonPopover, IonSearchbar, IonSegment, IonSegmentButton, IonSpinner, useIonViewDidEnter } from '@ionic/react';
import { addCircleOutline, arrowUpSharp, chevronDown, informationCircle, removeCircleOutline } from 'ionicons/icons';

// Interfaces
import { UserState } from '../interfaces/User.interface';
import { CollectionDetails } from '../config/collections.interface';

// Images
import { ReactComponent as GetReadyPlaceholder } from '../images/get-ready-placeholder.svg';
import swordsIcon from '../images/icons/swords.svg';
import collectionIcon from '../images/icons/cards.svg';
import gladiatorHelmet from '../images/icons/gladiator-helmet.svg';

// Components
import PlayerCardDisplay from '../components/play/PlayerCardDisplay';
import Power from '../components/play/Power';
import CardEquipment from '../components/shop/CardEquipment';
import ParticleOverlay from '../components/ui/ParticleOverlay';

// Services
import { ready } from '../services/GameServer';

// Styles
import './GetReady.scss';

// Utils
import { tilt } from '../Utils';

// Utils
import { getCollectionByID } from '../config/utils';
import { EquipmentType } from '../interfaces/Equipment.interface';

// Images
import { ReactComponent as GetReadyButtonFrame } from '../images/get-ready-btn-frame.svg';

const GetReady: React.FC = () => {

    const { collections, user, shopItems } = useContext<UserState>(UserContext);
    const { 

        // State
        room,
        requiredTokens,
        loadingToken,
        prepareToken,
        frame,
        wearable,
        myTokens,
    
        // Methods
        setLoadingToken,
        setPrepareToken,
        setFrame,
        setWearable,
        toggleTokenSelect,
        isReady,
        isEquipmentAttachedToAnyTeamMember,
        isThisEquipmentAttachedToThisToken
    
      }: PlayState = useContext(PlayContext);

    const [collection, setCollection] = useState<CollectionSlug | null>();
    const [collectionDetails, setCollectionDetails] = useState<CollectionDetails | undefined>();
    // const [searchText, setSearchText] = useState<string>('');
    const [equipment, setEquipment] = useState<EquipmentType>(EquipmentType.FRAMES);
    const [showPopover, setShowPopover] = useState(false);
    const [mobileView, setMobileView] = useState<'collection' | 'equip' | 'team'>(`collection`);
    const [isLarge, setIsLarge] = useState(false);

    /**
     * Determine if the screen is large
     * @returns 
     */
    const useIsLargeScreen = () => {
        useEffect(() => {
            const mediaQuery = window.matchMedia('(min-width: 64em)'); // Foundation's large breakpoint
            const handleMediaChange = () => setIsLarge(mediaQuery.matches);

            // Set initial state
            handleMediaChange();

            // Listen for changes
            mediaQuery.addEventListener('change', handleMediaChange);

            // Cleanup listener on unmount
            return () => mediaQuery.removeEventListener('change', handleMediaChange);
        }, []);

        return isLarge;
    };

    /**
     * Handle popover dismiss
     */
    const handlePopoverDismiss = () => {
        setShowPopover(false);
    };

    useIonViewDidEnter(() => {
        tilt('tilt-js-get-ready');
    });

    // Initialize the tilt.js effect
    useEffect(() => {       
        setTimeout(() => {
            tilt('tilt-js-get-ready');
        }, 250);
    }, [prepareToken?.frame?.id, frame?.id, wearable?.id, loadingToken, myTokens, prepareToken]);

    /**
     * Add to Team Button
     * @returns 
     */
    const ToggleTeamButton = () => {
        if (!user) {
            return null;
        }
        return (
            <>
            {isLarge ? (
                <div id='ready-controls-container' 
                    className={`w-100 flex justify-center items-center ${prepareToken && myTokens.length >= 3 && !isReady(prepareToken) ? `o-40 pointer-events-none` : ``}`} 
                    data-tokens={myTokens.length}
                    data-required-tokens={requiredTokens}
                >
                        <div id="team-controls" className={`relative ${isReady(prepareToken) ? `remove` : `add`} `}>
                            <GetReadyButtonFrame className='button-frame' />
                            <IonButton
                                color={`transparent`}
                                expand='block'
                                disabled={(myTokens.length >= 3 && !isReady(prepareToken)) || !prepareToken}
                                onClick={() => {
                                    toggleTokenSelect(prepareToken)
                                }}
                                style={{ width: '222px' }}
                            >
                                {isReady(prepareToken) ? (
                                    <span className="dib pt2 mt1 gladiator-arena">Remove</span>
                                ) : (
                                    <span className="dib pt2 mt1 gladiator-arena">Add to Team</span>
                                )}
                            </IonButton>
                        </div>
                </div>

            ) : (
                <div id="ready-controls-mobile-container">
                        <IonButton
                            color={'transparent'}
                            disabled={(myTokens.length >= 3 && !isReady(prepareToken)) || !prepareToken}
                            className={`${prepareToken && myTokens.length >= 3 && !isReady(prepareToken) ? `o-40 pointer-events-none` : ``}`}
                            onClick={() => {
                                toggleTokenSelect(prepareToken)
                            }}
                        >
                            {isReady(prepareToken) ? (
                                <>
                                <IonIcon icon={removeCircleOutline} className='mr2-l' size='large' />
                                <span className="button-text f5 show-for-large">Remove</span>
                                </>
                            ) : (
                                <>
                                <IonIcon icon={addCircleOutline} className='mr2-l' size='large' />
                                <span className="button-text f5 show-for-large">Add</span>
                                </>
                            )}
                            {prepareToken ? (
                                <IonBadge color='primary' class='ml1'>1</IonBadge>
                            ) : null}
                        </IonButton>
                </div>
            )}
            </>

        )
    }

    /**
     * Go To Battle Button
     * @returns 
     */
    const GoToBattleButton = () => {
        if (myTokens.length <= 0) {
            return null;
        }
        return (
            <>
            {isLarge ? (
                <div id="ready-controls" className="relative ready">
                    <div className='animate__animated animate__fadeInUp'>
                        <span className="blur-backdrop">
                            <GetReadyButtonFrame className='button-frame' />
                            <IonButton 
                                color={'transparent'} 
                                onClick={() => {
                                    if (room) {
                                        ready(user, room, myTokens);
                                    }
                                }}
                                style={{ width: '222px' }}
                            >
                                <span className="dib pt2 mt1 gladiator-arena">Ready</span>
                            </IonButton>
                        </span>
                    </div>
                </div>
            ) : (
                <div id="ready-controls-mobile" className='ml4'>
                    <IonButton 
                        color={'primary'} 
                        onClick={() => {
                            if (room) {
                                ready(user, room, myTokens);
                            }
                        }}
                        className='mr0'
                    >
                        <IonIcon icon={swordsIcon} className='mr2-l' size='large' />
                    </IonButton>
                </div>
            )}
            </>
        )
    }

    /**
     * Equipment Popover Text
     */
    const NoEquipmentPopoverText = () => {
        return (
            <div className="ph2 text-drop-shadow b pt-serif tc">
                <p className='lh-copy'>
                    Win battles, spend in-game currency (Gladii), ETH, or USDC to purchase battle-enhancing upgrades.
                </p>
                <p className='lh-copy'>Stay tuned for updates, equipment will be released over time.</p>
            </div>
        )
    }

    // Collection search listener
    useEffect(() => {

        setPrepareToken(null)
        setFrame(null)
        setWearable(null)

    //     // Filter the collection based on the search text
    //     const playerCards = document.querySelectorAll('.gladiator-page.get-ready .characters .player-card-container');
    //     playerCards?.forEach((card: any) => {
    //         const cardName = card.querySelector('.name')?.innerHTML;
    //         const tokenNumber = card.querySelector('.token')?.innerHTML;
    //         // If the card name or token number includes the search text, display the card
    //         if (cardName?.toLowerCase().includes(searchText.toLowerCase()) || tokenNumber?.toLowerCase().includes(searchText.toLowerCase())) {
    //             card.style.display = 'block';
    //         } else {
    //             card.style.display = 'none';
    //         }
    //     });

        tilt('tilt-js-get-ready');

        setCollectionDetails(collections.find((c) => c.slug === collection));

    }, [collection, collections, setFrame, setWearable, setPrepareToken]);

    return (
        <IonPage className="gladiator-page get-ready">
            <IonContent fullscreen className='transparent'>
                <div className="w-100 h-100 relative z-4">
                    <ParticleOverlay />
                    <div className="page-content">
                        <div className="relative row scrollable-page-content">
                            <div className="column small-12 relative z-4 flex flex-wrap justify-center">

                                {/* Gladiator Cards */}
                                {useIsLargeScreen() || mobileView === 'collection' ? (
                                <div className={`gladiator-cards column small-12 large-3 ph0 ph3-l`}>
                                    <div className="flex justify-center justify-between-l bb b--white-20 mb1">
                                        <h2 className="gladiator-arena w-100 tc tl-l mv0 text-drop-shadow pt2">
                                            {/* <span className="f6">Select</span><br /> */}
                                            <span className="pt3 truncate">Collectibles</span>
                                        </h2>
                                        {/* <div className='tl w-50 dn'>
                                            <IonSearchbar
                                                autocapitalize={'false'}
                                                showClearButton="always"
                                                placeholder="Search"
                                                mode='ios'
                                                color={'transparent'}
                                                onIonInput={(e: any) => {
                                                    const searchValue = e.target.value;
                                                    setSearchText(searchValue);
                                                }}
                                            ></IonSearchbar>
                                        </div> */}
                                    </div>
                                    <div className='flex justify-center justify-start-l'>
                                        <IonButton
                                            color={'transparent'}
                                            fill={'clear'}
                                            onClick={() => setShowPopover(true)}
                                            id='collection-selector'
                                        >
                                            <span className="dib pt0 mt0 pt-serif b white f5 text-drop-shadow">{collectionDetails?.name ?? 'Choose Collection'}</span>
                                            <IonIcon icon={chevronDown} className="ml2 white" />
                                        </IonButton>

                                        <IonPopover
                                            isOpen={showPopover}
                                            onDidDismiss={handlePopoverDismiss}
                                            trigger='collection-selector'
                                        >
                                            <IonList>
                                                {collections.map((c, i) => (
                                                    <IonItem
                                                        button
                                                        key={`opt_${i}`}
                                                        onClick={() => {
                                                            setCollection(null);
                                                            setCollection(c.slugLocal);
                                                            setTimeout(() => {
                                                                handlePopoverDismiss();
                                                            }, 100);
                                                        }}
                                                    >
                                                        <span className="pt3 db gladiator-arena">{c.name}</span>
                                                    </IonItem>
                                                ))}
                                            </IonList>
                                        </IonPopover>
                                    </div>
                                    <div className='column small-12 relative z-4 flex flex-wrap justify-center items-center characters content-start'>
                                        {user?.collectibles.filter(c => c.collection === collection).map((m, i) => {
                                            if (!isReady(m)) {
                                                return (
                                                    <div
                                                        key={`collectible-${m.token_id}-${m.collection}-${i}`}
                                                        className='player-card-container pa3 relative'
                                                    >
                                                        <PlayerCardDisplay card={m} click={() => {
                                                            if (prepareToken && prepareToken.token_id === m.token_id) {
                                                                setPrepareToken(null);
                                                            } else {
                                                                setCollection(m.collection as CollectionSlug);
                                                                setLoadingToken(true);
                                                                setPrepareToken(null);
                                                                setTimeout(() => {
                                                                    setLoadingToken(false);
                                                                    setPrepareToken(m);
                                                                }, 100)
                                                            }
                                                        }} />
                                                    </div>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}

                                        {collectionDetails && user?.collectibles.filter(c => c.collection === collection).length <= 0 ? (
                                            <div className="animate__animated animate__fadeIn w-100 tc pr3 pl2">
                                                <p className="pt-serif text-drop-shadow b lh-copy">
                                                    No collectibles available for the {collectionDetails?.name} collection.
                                                    <IonIcon 
                                                        icon={informationCircle} 
                                                        className="ml2" 
                                                        id="no-frames-tooltip" 
                                                    />
                                                </p>
                                                <IonPopover
                                                    trigger="no-frames-tooltip"
                                                    triggerAction="hover"
                                                    className="popover-tooltip"
                                                >
                                                    <NoEquipmentPopoverText />
                                                </IonPopover>
                                            </div>
                                        ) : null}

                                        {/* Display choose collectibles message if no collection has been selected */}
                                        {collectionDetails ? null : (
                                            <div className="animate__animated animate__fadeIn w-100 pl3 begin-message">
                                                <div className='mt2'>
                                                <IonIcon icon={arrowUpSharp} className="ml2" size='large' />
                                                <p className="pt-serif text-drop-shadow b lh-copy f4 mt2">
                                                    To begin, select a collection<br />from the dropdown above.
                                                </p>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                ) : null}

                                {/* Gladiator Preview */}
                                {useIsLargeScreen() || mobileView === 'equip' ? (
                                <div className={`gladiator-preview column small-12 large-6 tc flex flex-column`}>
                                    {/* <h3 className="gladiator-arena">Get Ready</h3> */}
                                    <div className="card-preview-container flex justify-center items-center relative">

                                        {/* Select character message */}
                                        {prepareToken === null && !loadingToken ? (
                                            <div className="animate__animated animate__zoomIn relative choose-wisely">
                                                <div className="relative player-card">
                                                    <GetReadyPlaceholder className='placeholder' />
                                                    <h3 className="gladiator-arena w5">Choose your combatant wisely</h3>
                                                </div>
                                            </div>
                                        ) : null}

                                        {/* Loading spinner */}
                                        {loadingToken ? (
                                            <div className="animate__animated animate__fadeIn text-drop-shadow-dark">
                                                <IonSpinner name="crescent" color={'dark'} />
                                            </div>
                                        ) : null}

                                        {/* Card Preview */}
                                        {prepareToken?.token_id ? (
                                            <div className="animate__animated animate__zoomIn">
                                                <PlayerCardDisplay 
                                                    card={prepareToken} 
                                                    clickFlip
                                                    stats
                                                />
                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                                ) : null}

                                {/* Gladiator Equip */}
                                {useIsLargeScreen() || mobileView === 'team' ? (
                                <div className={`gladiator-equip column small-12 large-3 tr flex flex-column justify-start relative`}>

                                    <div className="equipment">
                                        {/* Equipment */}
                                        <div className="flex justify-center justify-between-l bb b--white-20 mb3">
                                            <h2 className="gladiator-arena w-100 tc tl-l mv0 text-drop-shadow pt2">
                                                {/* <span className="f6">Select</span><br /> */}
                                                <span className="pt3 truncate">Equipment</span>
                                            </h2>
                                            {/* <div className='tl w-50 dn'>
                                                <IonSearchbar
                                                    autocapitalize={'false'}
                                                    showClearButton="always"
                                                    placeholder="Search equipment"
                                                    mode='ios'
                                                    color={'transparent'}
                                                ></IonSearchbar>
                                            </div> */}
                                        </div>
                                        <div>
                                            <IonSegment
                                                value={equipment}
                                                onIonChange={(e) => {
                                                    const value = e.detail.value as string;
                                                    const lowercaseValue = value.toLowerCase();
                                                    const equipmentType = lowercaseValue as EquipmentType;
                                                    setEquipment(equipmentType)
                                                }}
                                                mode='ios'
                                                color={'primary'}
                                            >
                                                <IonSegmentButton value={EquipmentType.FRAMES}>
                                                    <IonLabel><span className="pt-serif dib b f5 ttu">Frames</span></IonLabel>
                                                </IonSegmentButton>
                                                <IonSegmentButton value={EquipmentType.WEARABLES}>
                                                    <IonLabel><span className="pt-serif dib b f5 ttu">Wearables</span></IonLabel>
                                                </IonSegmentButton>
                                            </IonSegment>
                                        </div>
                                        <div className='column small-12 relative z-4 flex equipment pv2 mb2'>
                                            {collection ? (
                                                <>
                                                    {/* Display frames if there are any owned */}
                                                    {equipment === EquipmentType.FRAMES ? shopItems
                                                        .filter(item => item.type === EquipmentType.FRAMES)
                                                        .filter(s => getCollectionByID(s.collection!, collections)?.slug === collection)
                                                        .map((item, i) => {
                                                            const alreadyAttached = isEquipmentAttachedToAnyTeamMember(item) && (prepareToken && !isThisEquipmentAttachedToThisToken(prepareToken, item));
                                                            const attachedToThisToken = prepareToken ? isThisEquipmentAttachedToThisToken(prepareToken, item) : false;
                                                            return (
                                                                <CardEquipment 
                                                                    key={`collectible-purchase-${item.id}_${prepareToken?.id}`} 
                                                                    shopItem={item} 
                                                                    thumbnail 
                                                                    alreadyAttached={alreadyAttached} 
                                                                    attachedToThisToken={attachedToThisToken}
                                                                />
                                                            )
                                                    }) : null}

                                                    {/* Display no frames message if they have not yet purchased or made available */}
                                                    {equipment === EquipmentType.FRAMES && shopItems
                                                        .filter(item => item.type === EquipmentType.FRAMES)
                                                        .filter(s => getCollectionByID(s.collection!, collections)?.slug === collection).length <= 0 ? (
                                                            <div className="animate__animated animate__fadeIn w-100 tc pr3 pl2">
                                                                <p className="pt-serif text-drop-shadow b lh-copy">
                                                                    No frames available for the {collectionDetails?.name} collection.
                                                                    <IonIcon 
                                                                        icon={informationCircle} 
                                                                        className="ml2" 
                                                                        id="no-frames-tooltip" 
                                                                    />
                                                                </p>
                                                                <IonPopover
                                                                    trigger="no-frames-tooltip"
                                                                    triggerAction="hover"
                                                                    className="popover-tooltip"
                                                                >
                                                                    <NoEquipmentPopoverText />
                                                                </IonPopover>
                                                            </div>
                                                        ) : null}

                                                    {/* Display wearables if there are any owned */}
                                                    {equipment === EquipmentType.WEARABLES ? shopItems
                                                        .filter(item => item.type === EquipmentType.WEARABLES)
                                                        .filter(s => getCollectionByID(s.collection!, collections)?.slug === collection)
                                                        .map((item, i) => {
                                                            const alreadyAttached = isEquipmentAttachedToAnyTeamMember(item) && (prepareToken && !isThisEquipmentAttachedToThisToken(prepareToken, item));
                                                            const attachedToThisToken = prepareToken ? isThisEquipmentAttachedToThisToken(prepareToken, item) : false;
                                                            return (
                                                                <CardEquipment 
                                                                    key={`collectible-purchase-${item.id}_${prepareToken?.id}`} 
                                                                    shopItem={item} 
                                                                    thumbnail 
                                                                    alreadyAttached={alreadyAttached} 
                                                                    attachedToThisToken={attachedToThisToken}
                                                                />
                                                            )
                                                    }) : null}

                                                    {/* Display no wearables message if they have not yet purchased or made available */}
                                                    {equipment === EquipmentType.WEARABLES && shopItems
                                                        .filter(item => item.type === EquipmentType.WEARABLES)
                                                        .filter(s => getCollectionByID(s.collection!, collections)?.slug === collection).length <= 0 ? (
                                                            <div className="animate__animated animate__fadeIn w-100 tc pr3 pl2">
                                                                <p className="pt-serif text-drop-shadow b lh-copy">
                                                                    No wearables available for the {collectionDetails?.name} collection.
                                                                    <IonIcon 
                                                                        icon={informationCircle} 
                                                                        className="ml2" 
                                                                        id="no-frames-tooltip" 
                                                                    />
                                                                </p>
                                                                <IonPopover
                                                                    trigger="no-frames-tooltip"
                                                                    triggerAction="hover"
                                                                    className="popover-tooltip"
                                                                >
                                                                    <NoEquipmentPopoverText />
                                                                </IonPopover>
                                                            </div>
                                                        ) : null}

                                                </>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="team relative z-9 mt3">
                                        {/* Team */}
                                        <div className="flex justify-between bb b--white-20">
                                            <h2 className="gladiator-arena w-100 tl mv0 flex items-center text-drop-shadow">
                                                <span className="pt3 truncate">Your Team</span>
                                            </h2>
                                            <div>
                                                <div className="power-container-get-ready">
                                                    <Power tokens={myTokens} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`w-100 team-container team-count-${myTokens?.length}`}>
                                            {/* Loop through my tokens */}
                                            {myTokens.map((token, i) => {
                                                return (
                                                    <div 
                                                        key={`tokenbtn-${i}`} 
                                                        className={`team-member ${prepareToken && prepareToken.token_id === token.token_id ? `selected` : ``} `}>
                                                        <PlayerCardDisplay card={token} click={() => {
                                                            if (prepareToken && prepareToken.token_id === token.token_id) {
                                                                setPrepareToken(null)
                                                            } else {
                                                                setCollection(token.collection as CollectionSlug)
                                                                setLoadingToken(true)
                                                                setPrepareToken(null)
                                                                setTimeout(() => {
                                                                    setLoadingToken(false)
                                                                    setPrepareToken(token)
                                                                }, 300)
                                                            }
                                                        }} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    <div className="show-for-large">
                                    <GoToBattleButton />
                                    </div>

                                </div>
                                ) : null}

                            </div>
                        </div>

                        <div className="show-for-large">
                        <ToggleTeamButton />
                        </div>

                    </div>
                </div>
                <div id="tilt-js-get-ready" />
            </IonContent>


            <IonFooter class='hide-for-large'>
                <div className="w-100 flex justify-center mv2 bottom-nav">
                    <IonButton
                        color={'transparent'}
                        className={`${mobileView === 'collection' ? 'selected' : ''}`}
                        onClick={() => {
                            setMobileView('collection')
                        }}
                    >                        
                        <IonIcon src={gladiatorHelmet} className='mr2-l' size='large' />
                        <span className="button-text f5 show-for-large">Collection</span>
                    </IonButton>
                    <ToggleTeamButton />  
                    <IonButton
                        color={'transparent'}
                        className={`relative ${mobileView === 'team' ? 'selected' : ''}`}
                        onClick={() => {
                            setMobileView('team')
                        }}
                    >
                        <IonIcon src={collectionIcon} className='mr2-l' size='large' />
                        <span className="button-text f5 show-for-large">Team</span>
                        <IonBadge color='primary' class='ml1'>{myTokens.length}</IonBadge>
                    </IonButton>
                    <GoToBattleButton />
                </div>
            </IonFooter>
        </IonPage>
    );
};

export default GetReady;
