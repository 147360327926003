import React from 'react';
import './DigitalClock.scss';

interface Props {
    turnCount: number;
    turnMax: number;
}

const TurnCount: React.FC<Props> = ({ turnCount, turnMax }) => {
    return (
        <div className={`turnCount animate__animated animate__bounceIn`}>
            <span className="counter gladiator-arena">{turnCount}/{turnMax}</span>
        </div>
    );
}

export default TurnCount;
