import React from 'react';
import './DigitalClock.scss';

interface Props {
    myTurn: boolean | null;
    timerValue: number;
}

const DigitalClock: React.FC<Props> = ({ myTurn, timerValue }) => {
    return (
        <div className={`digitalClock myTurn-${myTurn} animate__animated animate__bounceIn`}>
            <p className="mv0"><span className="gladiator-arena mv0">{String(timerValue).padStart(2, '0')}</span></p>
        </div>
    );
}

export default DigitalClock;
