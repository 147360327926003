import React from 'react';
import { GameSummary } from '../../interfaces/GameSummary.interface';
import { useSpring, animated } from 'react-spring';

import './GameSummary.scss';
import { Player } from '../../generated/Player';
import { generateHumorousLabel } from '../../Utils';

interface GameSummaryProps {
    gameSummary: GameSummary;
    player: Player;
    opponent: Player;
    mode: 'winner' | 'loser' | 'tie';
}

const GameSummaryDisplay: React.FC<GameSummaryProps> = ({ gameSummary, player, opponent, mode }) => {

    const originalStats = mode === 'winner' ? gameSummary.originalWinnerStats : gameSummary.originalLoserStats;
    const updatedStats = mode === 'winner' ? gameSummary.updatedWinnerStats : gameSummary.updatedLoserStats;
    const battleStats = mode === 'winner' ? gameSummary.winnerBattleSummary : gameSummary.loserBattleSummary;
    const fontSize = 'f2';

    // Springs for existing stats
    const { value: winSpring } = useSpring({
        from: { value: originalStats.wins },
        to: { value: updatedStats.wins },
        config: { duration: 500 },
    });

    const { value: lossSpring } = useSpring({
        from: { value: originalStats.losses },
        to: { value: updatedStats.losses },
        config: { duration: 500 },
    });

    const { value: tieSpring } = useSpring({
        from: { value: originalStats.ties },
        to: { value: updatedStats.ties },
        config: { duration: 500 },
    });

    const { value: coinsEarnedSpring } = useSpring({
        from: { value: 0 },
        to: { value: updatedStats.coins - originalStats.coins },
        config: { duration: 1500 },
    });
    const { value: coinsSpring } = useSpring({
        from: { value: originalStats.coins },
        to: { value: updatedStats.coins },
        config: { duration: 1500 },
    });

    const { value: xpEarnedSpring } = useSpring({
        from: { value: 0 },
        to: { value: updatedStats.xp - originalStats.xp },
        config: { duration: 1500 },
    }); 
    const { value: xpSpring } = useSpring({
        from: { value: originalStats.xp },
        to: { value: updatedStats.xp },
        config: { duration: 1500 },
    });     

    // Springs for battle stats
    const { value: totalAttacksSpring } = useSpring({
        from: { value: 0 },
        to: { value: battleStats.total_attacks },
        config: { duration: 1000 },
    });

    const { value: totalDamageDealtSpring } = useSpring({
        from: { value: 0 },
        to: { value: battleStats.total_damage_dealt },
        config: { duration: 1000 },
    });

    const { value: totalHealsSpring } = useSpring({
        from: { value: 0 },
        to: { value: battleStats.total_heals },
        config: { duration: 1000 },
    });

    const { value: totalHealDealtSpring } = useSpring({
        from: { value: 0 },
        to: { value: battleStats.total_heal_dealt },
        config: { duration: 1000 },
    });

    const { value: totalAttacksReceivedSpring } = useSpring({
        from: { value: 0 },
        to: { value: battleStats.total_attacks_received },
        config: { duration: 1000 },
    });

    const { value: totalDamageReceivedSpring } = useSpring({
        from: { value: 0 },
        to: { value: battleStats.total_damage_received },
        config: { duration: 1000 },
    });

    return (
        <div className='game-summary pt-serif fw6'>

            <div className="row">
                <div className="column small-12 tc">
                    <p>{generateHumorousLabel(battleStats, player, opponent, mode === 'winner' ? true : false)}</p>
                </div>
                <div className="column small-12 medium-6 flex ma0 pa0">

                    {/* Battle Summary */}
                    <div className='battle-summary w-100'>
                        <p className="tc mt0 bb b--white-20 pb3">Battle Summary</p>
                        <p className={`attacks w-100 db flex justify-between ${fontSize} lh-copy mv0`}>
                            <span className="label mr1">Attacks:</span>
                            <animated.span className={`attacks-value mv0 lh-copy`}>
                            {totalAttacksSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                            </animated.span>
                        </p>
                        <p className={`damage-dealt w-100 db flex justify-between ${fontSize} lh-copy mv0`}>
                            <span className="label mr1">Damage Dealt:</span>
                            <animated.span className={`damage-dealt-value mv0 lh-copy`}>
                            {totalDamageDealtSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                            </animated.span>
                        </p>
                        <p className={`heals w-100 db flex justify-between ${fontSize} lh-copy mv0`}>
                            <span className="label mr1">Heals:</span>
                            <animated.span className={`heals-value mv0 lh-copy`}>
                            {totalHealsSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                            </animated.span>
                        </p>
                        <p className={`heal-dealt w-100 db flex justify-between ${fontSize} lh-copy mv0`}>
                            <span className="label mr1">Heal Power Used:</span>
                            <animated.span className={`heal-dealt-value mv0 lh-copy`}>
                            {totalHealDealtSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                            </animated.span>
                        </p>
                        <p className={`attacks-received w-100 db flex justify-between ${fontSize} lh-copy mv0`}>
                            <span className="label mr1">Attacks Received:</span>
                            <animated.span className={`attacks-received-value mv0 lh-copy`}>
                            {totalAttacksReceivedSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                            </animated.span>
                        </p>
                        <p className={`damage-received w-100 db flex justify-between ${fontSize} lh-copy mv0`}>
                            <span className="label mr1">Damage Received:</span>
                            <animated.span className={`damage-received-value mv0 lh-copy`}>
                            {totalDamageReceivedSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                            </animated.span>
                        </p>
                    </div>

                </div>
                <div className="column small-12 medium-6 flex ma0 pa0">

                    <div className='battle-summary w-100'>

                        <p className="tc mt0 bb b--white-20 pb3">Stats</p>
                        <p className={`wins w-100 db flex justify-between ${fontSize} lh-copy mv0`}>
                            <span className="label mr1">Won:</span>
                            <animated.span className={`win-value mv0 lh-copy`}>
                            {winSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                            </animated.span>
                        </p>
                        <p className={`losses w-100 db flex justify-between ${fontSize} lh-copy mv0`}>
                            <span className="label mr1">Lost:</span>
                            <animated.span className={`loss-value mv0 lh-copy`}>
                            {lossSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                            </animated.span>
                        </p>
                        <p className={`ties w-100 db flex justify-between ${fontSize} lh-copy mv0`}>
                            <span className="label mr1">Tie:</span>
                            <animated.span className={`tie-value mv0 lh-copy`}>
                            {tieSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                            </animated.span>
                        </p>
                        <p className={`coins w-100 db flex justify-between ${fontSize} lh-copy mv0`}>
                            <span className="label mr1">Gladii:</span>
                            <span>
                                <span className="earned green animate__animated animate_bounceIn mr4">+
                                    <animated.span>
                                        {coinsEarnedSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                                    </animated.span>
                                </span>
                                <animated.span className={`coin-value mv0 lh-copy`}>
                                {coinsSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                                </animated.span>
                            </span>
                        </p>
                        <p className={`xp w-100 db flex justify-between ${fontSize} lh-copy mv0`}>
                            <span className="label mr1">XP:</span>
                            <span>
                                <span className="earned green animate__animated animate_bounceIn mr4">+
                                    <animated.span>
                                        {xpEarnedSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                                    </animated.span>
                                </span>
                                <animated.span className={`xp-value mv0 lh-copy`}>
                                {xpSpring?.to((x: number) => Math.floor(x).toLocaleString())}
                                </animated.span>
                            </span>
                        </p>

                    </div>

                </div>
            </div>

        </div>
    );
};

export default GameSummaryDisplay;
