import { useEffect, useState } from "react";

export default function useMousePosition() {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  useEffect(() => {
    const mouseMoveHandler = (event: any) => {
      const { clientX, clientY } = event;
      const tooltipWidth = 250; // Adjust this to the actual tooltip width
      const offset = 10;

      // Set initial position with offset
      let xPosition = clientX + offset;
      let yPosition = clientY + offset;

      // Check if the tooltip escapes the right side of the screen
      if (xPosition + tooltipWidth > window.innerWidth) {
        xPosition = window.innerWidth - tooltipWidth - offset;
      }

      // Check if the tooltip escapes the left side of the screen
      if (xPosition < 0) {
        xPosition = offset;
      }

      // Update state with adjusted position
      setMousePosition({ x: xPosition, y: yPosition });
    };

    document.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, []);

  return mousePosition;
}
