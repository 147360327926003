import { AbilityLib, AbilityType } from "../../../interfaces/Ability.interface";
import { apexStrike, chaoticLash, corruptionWave, dominanceProtocol } from "./fx";
import { ReactComponent as ApexStrike } from '../../../collections/bedlams/abilities/bedlams_apex-strike.svg';
import { ReactComponent as ChaoticLash } from '../../../collections/bedlams/abilities/bedlams_chaotic-lash.svg';
import { ReactComponent as CorruptionWave } from '../../../collections/bedlams/abilities/bedlams_corrupted-wave.svg';
import { ReactComponent as DominanceProtocol } from '../../../collections/bedlams/abilities/bedlams_dominance-protocol.svg';

const abilities: AbilityLib[] = [
    {
        "name": "Dominance Protocol",
        "power": 65,
        "energy": 6,
        "group": false,
        "type": AbilityType.HealDefense,
        "self": true,
        "description": "Assert dominance by raising your defenses, to intimidate your enemies.",
        "cssClass": "dominance-protocol",
        "icon": <DominanceProtocol />,
        "method": dominanceProtocol
    },
    {
        "name": "Chaotic Lash",
        "power": 50,
        "energy": 4,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Unleash a torrent of madness, spreading confusion and devastation to a single enemy.",
        "cssClass": "chaotic-lash",
        "icon": <ChaoticLash />,
        "method": chaoticLash
    },
    {
        "name": "Apex Strike",
        "power": 75,
        "energy": 7,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Channel the primal power of the ancient beasts, delivering a single devastating blow.",
        "cssClass": "apex-strike",
        "icon": <ApexStrike />,
        "method": apexStrike
    },
    {
        "name": "Corruption Wave",
        "power": 30,
        "energy": 3,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Unleash a wave of corruption, decimating your enemies.",
        "cssClass": "corruption-wave",
        "icon": <CorruptionWave />,
        "method": corruptionWave
    }
]

export default abilities;