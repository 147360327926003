// Hooks
import { useContext, useEffect, useState } from "react";
import useMousePosition from "../../hooks/useMousePosition";
import { UserState } from "../../interfaces/User.interface";

// Styles
import './GladiatorCursor.scss'
import { UserContext } from "../../Context";
import { PlayContext, PlayState } from "../../pages/PlayContext";
import { AbilityType } from "../../interfaces/Ability.interface";

const GladiatorCursor = () => {

  const { tooltip, showTooltip } = useContext(UserContext);
  const { myTurn, myEnergy, abilitySelected } = useContext(PlayContext);
  const mousePosition = useMousePosition();

  // Default to (0, 0) if mousePosition.x or y is null
  const x = mousePosition?.x ?? 0;
  const y = mousePosition?.y ?? 0;

  const [tooltipStyle, setTooltipStyle] = useState({ left: 0, top: 0 });

  useEffect(() => {
    const updateTooltipPosition = () => {
      const margin = 16; // 1rem in pixels
      const tooltipWidth = 310; // Max width of the tooltip
      const tooltipHeight = 200; // Approximate height of the tooltip

      let left = x - tooltipWidth / 2;
      let top = y - tooltipHeight - margin;

      // Ensure the tooltip stays within the viewport boundaries
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (left < margin) {
        left = margin;
      } else if (left + tooltipWidth > viewportWidth - margin) {
        left = viewportWidth - tooltipWidth - margin;
      }

      if (top < margin) {
        top = y + margin; // Flip below the cursor if there's not enough space above
      }

      setTooltipStyle({ left, top });
    };

    updateTooltipPosition();
  }, [x, y]);

  /**
   * Calculate the power of the ability
   * @returns Power of the ability
   */
  // const calcAbilityPower = (mode: 'total' | 'original'): number => {
  //   let power = tooltip.data?.power || 0;
  //   if (mode === 'original') {
  //     // if (tooltip.collectible?.frame?.metadata?.attack_boost) {
  //     //   power -= +tooltip.collectible.frame.metadata.attack_boost;
  //     // }
  //     // if (tooltip.collectible?.wearable?.attack_boost) {
  //     //   power -= +tooltip.collectible.wearable.attack_boost;
  //     // }
  //     return power;
  //   } else {
  //     return power;
  //   }
  // };
  
  useEffect(() => {
    // console.log(tooltip, showTooltip)
  }, [tooltip, showTooltip, myEnergy, abilitySelected])

  return (
    <>
    {(myTurn) && (
      <div id="game-tooltip" className={`dot ${showTooltip ? 'appear' : ''}`} style={tooltipStyle}>
          {tooltip?.type === 'ability' && (
          <div className="pa3 tooltip pt-serif b">
            <div className="flex justify-between">
              <p className="mt0 b mb2 underline small-4 ttu text-drop-shadow">Ability:</p>
              {(tooltip?.data?.energy) && (myEnergy && myEnergy >= tooltip?.data?.energy) ? (
                <>
                {abilitySelected?.id === tooltip.data.id ? (
                  <p className="mt0 b mb2 green small-7 tr text-drop-shadow">Selected and ready!</p>
                ) : (
                  <p className="mt0 b mb2 green small-7 tr text-drop-shadow">Ready to use!</p>
                )}
                </>
              ) : null}
              {(tooltip?.data?.energy) && ((myEnergy && myEnergy < tooltip?.data?.energy) || (!myEnergy)) ? (
                <p className="mv0 b mb2 red small-7 text-drop-shadow">Need more energy</p>
              ) : null}
            </div>
            <h3 className="gladiator-arena mb1 text-drop-shadow tc w-100">{tooltip.data?.name}</h3>
            <div className="row">
              <div className="column small-4 ph0 tc text-drop-shadow">
                <p className="mt0 b mb2 underline ttu">Energy:</p>
                <p className="mt0 b mb2 ttu f3">{tooltip?.data?.energy}</p>
              </div>
              <div className="column small-4 ph1 tc text-drop-shadow">
                <p className="mt0 b mb2 underline ttu">Effects:</p>
                <p className="mt0 b mb2 flex flex-column ttu f5">
                  {tooltip?.data?.type === AbilityType.HealDefense && <span className="green">{`+${tooltip.data.power} defense`}</span>}
                  {tooltip?.data?.type === AbilityType.Attack && <span className="red">{`${tooltip.data.power} damage`}</span>}
                </p>
              </div>
              <div className="column small-4 ph0 tc text-drop-shadow">
                <p className="mt0 b mb2 underline ttu">Group:</p>
                <p className="mt0 b mb2 f4">{tooltip?.data?.group ? `Yes` : `No` }</p>
              </div>
              {tooltip?.collectible?.wearable?.id || tooltip?.collectible?.frame?.id ? (
                <div className="column small-12 text-drop-shadow mt3">
                <div className="flex flex-wrap justify-between">
                  <p className="mt0 b mb2 underline small-6">Wearables:</p>
                  <p className="mt0 b mb2 flex flex-column small-6">
                    {tooltip?.collectible?.wearable?.id && <span className="white">{tooltip?.collectible?.wearable?.name}</span>}
                    {tooltip?.collectible?.frame?.id && <span className="white">{tooltip?.collectible?.frame?.name}</span>}
                  </p>
                </div>              
                </div>
            ) : null}
              <div className="column small-12 ph1 tc bt b--white-10 pt3 mt2">
                  <p className="mt0 mb2 text-drop-shadow">
                    <span className="underline b mb2 db ttu">How to use:</span>
                  </p>
                  <p className="mt0 mb2 text-drop-shadow ph0">
                    {tooltip?.data?.type === AbilityType.HealDefense && (
                      <span className="mt1 mb3 lh-copy">{'Click on this ability, then click one of your cards to raise its defense value.'}</span>
                    )}
                    {tooltip?.data?.type === AbilityType.Attack && (
                      <span className="mt1 mb3 lh-copy">{`Click on this ability, then click one of your opponent's cards to attack ${tooltip.data.group ? `the group` : `it`}.`}</span>
                    )}
                  </p>
              </div>
            </div>



         
          </div>
          )}
      </div>
    )}
    </>
  );
};

export default GladiatorCursor;